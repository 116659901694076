export interface CreateVoucher {
  system_type: string;
  description: string;
  purchase_qty: number;
  name: string;
  phone_number: string;
  application_type: string;
  purchase_item_id: string;
  payment_amount: number;
  extra?: { [key: string]: any };
}

export interface PaymentVerify {
  payment_status: string;
  ebpNo: string;
  hash_value: string;
}

export interface RedirectionData {
  code: string;
  token: string;
  sessionId: string;
  lang: string;
}

export interface PaymentRate {
  id: number;
  system_type: string;
  application_type: string;
  rate: number;
  fiscal_year: number;
  fiscal_year_name: string;
}

export enum SystemType {
  SEED_IMPORT_EXPORT = "Seed Import Export",
  SEED_VARIETAL_REGISTERATION = "Seed Varietal Registration",
  SEED_CERTIFICATION = "Seed Certification",
  SEED_INSPECTOR = "Seed Inspector"
}

export enum ApplicationType {
  SEED_IMPORT_EXPORT = "Seed Import Export",
  SEED_VARIETAL_REGISTRATION = "Seed Varietal Registration",
  PRIOR_INFORMATION_FORM = "Prior Information Form",
  SEED_LAB_TEST_REQUEST = "Seed Lab Test Request",
  SEED_INSPECTOR_RENEWAL = "Seed Inspector Renewal",
  SEED_IMPORT_PERMIT_RENEWAL = "Seed Import Permit Renewal"
}

export interface Choices {
  label: string;
  value: string;
}

export interface Voucher {
  id: number;
  voucher_number: number;
  ename: string;
  system_type: string;
  application_type: string;
  purchase_item_id: string;
  purchase_item_qty: number;
  ebp_number: string;
  token_id: string;
  amount: number;
  request: {};
  response: {};
  status: string;
}

export interface TransactionReport {
  id: number;
  txn_id: string;
  txn_datetime: string;
  request: {};
  response: {};
  date_created: string;
  date_modified: string;
  voucher: Voucher;
}

export interface PaginatedTransactionReport {
  next: string;
  previous: string;
  page: number;
  page_size: number;
  count: number;
  total_pages: number;
  results: TransactionReport[];
}

export interface TransactionReportModified {
  id: number;
  amount: number;
  application_type: string;
  ebp_number: string;
  paid_by: string;
  paid_to: string;
  phone_number: string;
  system_type: string;
  txn_date: string;
}

export interface PaginatedTransactionReportModified {
  next: string;
  previous: string;
  page: number;
  page_size: number;
  count: number;
  total_pages: number;
  results: TransactionReportModified[];
}
