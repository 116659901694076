



























































































































































































































































import { Vue, Component } from "vue-property-decorator";
// import { FCGOPaymentStore } from "@/store/modules";
// import { CreateVoucher, RedirectionData } from "@/store/models/payment";
import { ApplicationType, SystemType } from "@/store/models/payment";

import { getNestedFormData } from "@/utils/helper.utils";
import axios from "axios";
import { nepaliDate } from "@/store/modules/dateConverter";

@Component({
  components: {}
})
export default class PaymentSuccessPage extends Vue {
  ebpNo: any = "";
  hash_value: any = "";
  response: any = {};
  verifying = true;

  // get paymentVerifiedData() {
  //   return FCGOPaymentStore.PaymentVerificationResponse;
  // }

  receiptRedirect(url: string) {
    window.open(url, "_blank");
  }

  nepaliDate(date: string) {
    return nepaliDate(date);
  }

  get systemType() {
    return SystemType;
  }

  get applicationType() {
    return ApplicationType;
  }

  setApplicationTypeName(applicationType: string) {
    if (applicationType == this.applicationType.SEED_IMPORT_EXPORT) {
      return "Seed Import Permit Application";
    } else {
      return applicationType;
    }
  }

  async beforeMount() {
    this.ebpNo = this.$route.query.ebpNo;
    this.hash_value = this.$route.query.hashValue;

    const data = {
      payment_status: "Success",
      ebpNo: this.ebpNo,
      hash_value: this.hash_value
    };
    let paymentVerifyFormData = new FormData();
    let paymentVerifyData = getNestedFormData(paymentVerifyFormData, data);

    // await FCGOPaymentStore.paymentVerify(paymentVerifyData);
    this.verifying = true;
    this.response = await axios.post(
      `https://desisapi.pathway.com.np/api/transaction/payment/fcgo/paid_voucher/verify/`,
      paymentVerifyData
    );
    this.verifying = false;
  }
}
